import React from 'react'
import logImages from '../../../assets/cta_3-1.png'
import { Container, Grid, } from '@mui/material'
import Decentralized from '../../../assets/icons-gif/decentralized.gif'
import Fast from '../../../assets/icons-gif/fast.gif'
import Scalable from '../../../assets/icons-gif/scalable.gif'
import { Link } from 'react-router-dom'

const SecurelyConnected = () => {
    return (
        <div className='SecurelyConnected py-32 relative'>
        <div className='absolute top-0 z-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain w-full opacity-50' alt='' />
            </div>
            <Container maxWidth="xl" className='relative'>
                <Grid container spacing={2}>
                    <Grid item lg="7">
                        <div className=''>
                            <div className='mb-10' >
                                <div className='text-white text-4xl font-bold mb-4'>Securely Connected,</div>
                                <div className='text-white text-5xl font-bold mb-4 gradient-text'>Boundlessly Innovation</div>
                                <div className='text-slate-300 text-xl font-normal mb-5'>Welcome to Palm Smart Chain, where innovation meets security. We drive decentralized solutions 
                                    across finance, gaming, healthcare, and beyond. With a focus on transparency, security, and 
                                    inclusivity, Palm is revolutionizing the way digital assets and data are handled. Join us in shaping the 
                                    future of decentralized technology</div>
                                <div className='my-5'>
                                    <Link to='https://wallet.palmsmartchain.io/' target='_blank' className='buttons StartBuilding'>Palm Wallet</Link>
                                </div>
                            </div>
                            <div className="lg:flex md:flex sm:block block items-center gap-3 mb-4 relative z-10">
                                <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full card_new my-2">
                                    <div className='inner_card border-2 border-slate-800'>
                                        <div className='mb-2 flex items-center gap-3'>
                                            <div className='iconsImage'>
                                                <img src={Decentralized} className='w-11' />
                                            </div>
                                            <div className='contentcards text-2xl text-white'>
                                                Decentralized
                                            </div>
                                        </div>
                                        <div className='text-slate-300 text-sm'>
                                            Unlock the full potential of decentralization with Palm Smart Chain. We ensure transparency, 
                                            security, and autonomy free from central control empowering you to take charge of your digital 
                                            world. 
                                        </div>
                                    </div>
                                </div> 
                                <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full card_new my-2">
                                    <div className='inner_card border-2 border-slate-800'>
                                        <div className='mb-2 flex items-center gap-3'>
                                            <div className='iconsImage'>
                                                <img src={Fast} className='w-12' />
                                            </div>
                                            <div className='contentcards text-2xl text-white'>
                                                Fast
                                            </div>
                                        </div>
                                        <div className='text-slate-300 text-sm'>
                                            Experience lightning-fast, secure transactions on Palm Smart Chain. With minimal fees, we enhance 
                                            efficiency and reliability, delivering a seamless experience for all users.                                    
                                        </div>
                                    </div>
                                </div>  
                            </div> 
                            <div className="w-full card_new h-full relative z-10">
                                <div className='inner_card h-full border-2 border-slate-800'>
                                    <div className='mb-2 flex items-center gap-3'>
                                        <div className='iconsImage'>
                                            <img src={Scalable} className='w-12' />
                                        </div>
                                        <div className='contentcards text-2xl text-white'>
                                            Scalable
                                        </div>
                                    </div>
                                    <div className='text-slate-300 text-sm'>
                                        Palm Smart Chain offers a robust, scalable infrastructure designed to handle growing transactions 
                                        and users. We ensure consistent performance and seamless expansion, empowering all stakeholders 
                                        as demand increases. 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="5" md="5" sm="12" xs="12">
                        <div className='animate-pulse'>
                            <img src={logImages} className='w-full lg:w-96 m-auto' alt='' />
                        </div>
                    </Grid>
                     
                </Grid>
            </Container>
        </div>
    )
}

export default SecurelyConnected