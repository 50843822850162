import { Container, Grid } from '@mui/material'
import React from 'react'

const Roadmap = () => {
    return (
        <div className='py-36 relative bg-zinc-950'>
            <div className='absolute top-0 z-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50' alt='' />
            </div>
            <Container maxWidth="xl">
                <div className="text-center mb-20">
                    <div className="text-2xl font-normal text-white mb-5">Palm Smart Chain</div>
                    <div className="lg:text-6xl md:text-5xl sm:text-4xl text-4xl font-bold gradient-text">Roadmap: Paving <br /> the Future of Blockchain</div>
                </div>
                <Grid item lg="12">
                    <div className='md:container mx-auto'>
                        <div class="roadmap-section">
                            <div class="tab-content">
                                <div class="tabs-1-content flex gap-5 md:gap-20 relative justify-center">
                                    <div class="lg:absolute md:absolute left-0 progress_bar text-white flex flex-col h-full w-1 order-1 md:order-2 lg:ml-6 md:ml-6 sm:ml-2 ml-1">
                                        <div class="h-[515px] sm:h-[390px] lg:h-[360px] relative bg-newGradient">
                                            <span class="text-black h-3 w-3 bg-SecGradient p-2 rounded-full flex justify-center items-center absolute -left-1.5"></span>
                                            <span class="text-primary whitespace-pre text-xs font-semibold relative left-4 -top-1">Quarter 3, 2024</span>
                                        </div>
                                        <div class="h-[515px] sm:h-[390px] lg:h-[360px] relative bg-newGradient">
                                            <span class="text-black h-3 w-3 bg-SecGradient p-2 rounded-full flex justify-center items-center absolute -left-1.5 lg:top-[3rem] md:top-[3rem] sm:top-[37%] top-[81%]"></span>
                                            <span class="text-primary whitespace-pre text-xs font-semibold relative left-4 lg:top-[3rem] md:-top-[3rem] sm:top-[37%] top-[80%]">Quarter 4, 2024</span>
                                        </div>
                                        <div class="h-[515px] sm:h-[390px] lg:h-[360px] relative bg-newGradient">
                                            <span class="text-black h-3 w-3 bg-SecGradient p-2 rounded-full flex justify-center items-center absolute -left-1.5 lg:top-[7rem] md:top-[7rem] sm:top-[26%] top-[88%]"></span>
                                            <span class="text-second whitespace-pre text-xs font-semibold relative left-4 lg:top-[7rem] md:top-[7rem] sm:top-[25%] top-[88%]">Quarter 1, 2025</span>
                                        </div>
                                        <div class="h-[515px] sm:h-[390px] lg:h-[360px] relative bg-newGradient">
                                            <span class="text-black h-3 w-3 bg-SecGradient p-2 rounded-full flex justify-center items-center absolute -left-1.5 lg:top-[4.75rem] md:top-[4.75rem] sm:-top-[29%] top-[44%]"></span>
                                            <span class="text-second whitespace-pre text-xs font-semibold relative left-4 lg:top-[4.75rem] md:top-[4.75rem] sm:-top-[29%] top-[44%]">Quarter 2, 2025</span>
                                        </div>
                                         <div class="h-[515px] sm:h-[390px] lg:h-[360px] relative bg-newGradient">
                                            <span class="text-black h-3 w-3 bg-SecGradient p-2 rounded-full flex justify-center items-center absolute -left-1.5 lg:-top-[6.25rem] md:-top-[6.25rem] sm:top-[24px] -top-[76px]"></span>
                                            <span class="text-second whitespace-pre text-xs font-semibold relative left-4 lg:-top-[6.25rem] md:-top-[6.25rem] sm:top-[24px] -top-[76px]">Quarter 3, 2025</span>
                                        </div> 

                                    </div>
                                    <div class="title-description text-white flex flex-col order-3 md:order-3 justify-between lg:ml-6 md:ml-6 sm:ml-2 ml-1">
                                        <div class="content lg:mb-12">
                                            <div>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative mt-7 hidden lg:flex">
                                                    <div class="first_tree grid grid-cols-2 lg:grid-cols-3 gap-20 z-10 relative">
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-purple-900 hover:bg-purple-700 relative">Building Project Team</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-purple-900 hover:bg-purple-700">Socials Setup</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-purple-900 hover:bg-purple-700 lg:-right-[84px]">Developing the Palm Project details</div>
                                                        <div class="line lg:bg-white lg:max-w-80p lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-5 lg:left-0 lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-[125px] lg:after:inline-block lg:after:absolute lg:after:right-16"></div>
                                                    </div>
                                                    <div class="first_tree flex gap-20 z-10 justify-end items-center">
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-purple-900 hover:bg-purple-700">Creating the Roadmap</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-purple-900 hover:bg-purple-700">Writing the Whitepaper</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-purple-900 hover:bg-purple-700">Website Development</div>
                                                        <div class="line_2 lg:bg-white lg:max-w-92t lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-53 xl:top-50p lg:left-unset lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-36 lg:after:inline-block"></div>
                                                    </div>
                                                    <div class="first_tree gap-20 z-10 flex justify-start items-center">
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-purple-900 hover:bg-purple-700">Developing and Launching</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-purple-900 hover:bg-purple-700">Testing and Feedback.</div>
                                                        <div class="line_3 lg:bg-white lg:max-w-65 xl:max-w-80 lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-93p lg:left-0 lg:right-0"></div>
                                                    </div>
                                                </div>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative lg:hidden mt-6">
                                                    <div class="first_tree grid sm:grid-cols-2 grid-cols-1 gap-5 z-10 relative">
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-purple-800 hover:bg-purple-700 relative">Building Project Team</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Socials Setup</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Developing the Palm Project details</div>
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-purple-800 hover:bg-purple-700">Creating the Roadmap</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Writing the Whitepaper </div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Website Development</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Developing and launching</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Testing and Feedback.</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Palm Explorer Development</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Wallet Development</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Launch Preparation</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">ICO Launch</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Staking Launch</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-purple-800 hover:bg-purple-700">Launching the Project website to the public</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="content lg:mb-12">
                                            <div className='relative'>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative hidden lg:flex">
                                                    <div class="first_tree grid grid-cols-2 lg:grid-cols-3 gap-20 z-10 justify-between relative">
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Planning Palm Wallet App</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Published App on Android</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700 lg:-right-[185px]">Published App on IOS</div>
                                                        <div
                                                            class="line lg:bg-white lg:max-w-80p lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-5 lg:left-0 lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-[125px] lg:after:inline-block lg:after:absolute lg:after:right-16"
                                                        ></div>
                                                    </div>
                                                    <div class="first_tree flex gap-20 z-10 justify-end items-center">
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Launch Aman Staking</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Social media accounts active and engaging with the Community</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Community Building</div>
                                                        <div class="line_2 lg:bg-white lg:max-w-92t lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-53 xl:top-50 lg:left-unset lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-36 lg:after:inline-block"></div>
                                                    </div>
                                                    <div class="first_tree gap-20 z-10 flex justify-start items-center">
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Developing NFTs</div>
                                                        <div class="px-4 py-2 border border-primary max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Developing Palm Pay</div>
                                                        <div class="line_3 lg:bg-white lg:max-w-65 xl:max-w-80 lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-93p lg:left-0 lg:right-0"></div>
                                                    </div>
                                                </div>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative lg:hidden mt-6">
                                                    <div class="first_tree grid sm:grid-cols-2 grid-cols-1 gap-5 z-10 relative">
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Planning Palm Wallet App</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-zinc-950 hover:bg-purple-700">Published App on Android</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-zinc-950 hover:bg-purple-700">Published App on IOS</div>
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Launch Aman Staking</div> 
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Social media accounts active and engaging with the Community</div> 
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Community Building</div> 
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Developing NFTs</div> 
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Developing Palm Pay</div>  
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div class="content lg:mb-10">
                                            <div>
                                                <div class="main-tree-view  flex-col gap-20 bg-contain bg-no-repeat relative hidden lg:flex">
                                                    <div class="first_tree grid grid-cols-2 lg:grid-cols-3 gap-20 z-10 relative">
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Launch Rema</div>
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Growing Community</div>
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700 lg:-right-[244px]">Launch Ruka</div>
                                                        <div class="line lg:bg-white lg:max-w-80p lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-5 lg:left-0 lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-[125px] lg:after:inline-block lg:after:absolute lg:after:right-16"></div>
                                                    </div>
                                                    <div class="first_tree flex gap-20 z-10 justify-end items-center">
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Listing Palm coin on Exchange</div>
                                                    </div>

                                                </div>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative lg:hidden mt-6">
                                                    <div class="first_tree grid sm:grid-cols-2 grid-cols-1 gap-5 z-10 relative">
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Launch Rema</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-zinc-950 hover:bg-purple-700">Growing Community</div>
                                                        <div class="px-4 py-2 border border-primary rounded text-white relative bg-zinc-950 hover:bg-purple-700">Launch Ruka</div>
                                                        <div class="px-4 py-2 border border-primary rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Listing Palm coin on Exchange</div>  
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                         <div class="content lg:mb-12">
                                            <div>
                                                <div class="main-tree-view flex-col mt-9 gap-20 bg-contain bg-no-repeat relative hidden lg:flex">
                                                    <div class="first_tree grid grid-cols-2 lg:grid-cols-3 gap-20 z-10 relative">
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Launch ECO</div>
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Launch Challenge Me</div> 
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700 lg:-right-[162px]">Listing Palm in Exchange</div> 
                                                        <div
                                                            class="line lg:bg-white lg:max-w-80p lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-5 lg:left-0 lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:inline-block lg:after:absolute lg:after:right-16"
                                                        ></div>
                                                    </div> 
                                                </div>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative lg:hidden mt-6">
                                                    <div class="first_tree grid sm:grid-cols-2 grid-cols-1 gap-5 z-10 relative">
                                                        <div class="px-4 py-2 border border-second rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Launch ECO</div>
                                                        <div class="px-4 py-2 border border-second rounded text-white relative bg-zinc-950 hover:bg-purple-700">Launch Challenge Me</div>
                                                        <div class="px-4 py-2 border border-second rounded text-white relative bg-zinc-950 hover:bg-purple-700">Listing Palm in Exchange</div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 

                                        <div class="content lg:mb-12">
                                            <div className=''>
                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative hidden lg:flex">
                                                     
                                                    <div class="first_tree grid grid-cols-2 lg:grid-cols-3 gap-20 z-10 relative"> 
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Marketing Campaign</div>
                                                        <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Grow Community, PALM Coin Holders, and Users</div>
                                                        <div class="line lg:bg-white lg:max-w-80p lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-5 lg:left-0 lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-[125px] lg:after:inline-block lg:after:absolute lg:after:right-16"></div>
                                                    </div>
                                                    <div class="first_tree flex gap-20 z-10 justify-end items-center">
                                                    <div class="px-4 py-2 border border-second max-w-fit rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">NFT Marketplace Development and Release</div>
                                                    <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Marketing Campaign Focus: Increase TVL</div>
                                                        <div class="line_2 lg:bg-white lg:max-w-92t lg:w-full lg:h-0.5 lg:absolute lg:-z-10 lg:top-53 xl:top-50p lg:left-unset lg:right-0 lg:after:bg-white lg:after:w-0.5 lg:after:h-36 lg:after:inline-block"></div>
                                                    </div>
                                                    {/* <div class="first_tree flex gap-20 z-10 justify-start items-center">
                                                    <div class="px-4 py-2 border border-second max-w-fit rounded text-white relative bg-zinc-950 hover:bg-purple-700">Loan and Borrow Platform Release</div>
                                                     </div> */}
                                                     
                                                </div>

                                                <div class="main-tree-view flex-col gap-20 bg-contain bg-no-repeat relative lg:hidden mt-6">
                                                        <div class="first_tree grid sm:grid-cols-2 grid-cols-1 gap-5 z-10 relative">  
                                                        <div class="px-4 py-2 border border-second rounded z-10 text-white bg-zinc-950 hover:bg-purple-700 relative">Marketing Campaign</div>
                                                        <div class="px-4 py-2 border border-second rounded text-white relative bg-zinc-950 hover:bg-purple-700">Grow Community, PALM Coin Holders, and Users</div>
                                                        <div class="px-4 py-2 border border-second rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">NFT Marketplace Development and Release</div>
                                                        <div class="px-4 py-2 border border-second rounded text-white relative bg-zinc-950 hover:bg-purple-700">Marketing Campaign Focus: Increase TVL</div>
                                                        {/* <div class="px-4 py-2 border border-second rounded z-10 text-white bg-zinc-950 hover:bg-purple-700">Loan and Borrow Platform Release</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Container>
        </div>
    )
}

export default Roadmap