import { Container, Grid } from '@mui/material'
import React from 'react'

import telegram from '../../assets/social-media/telegram.svg'
import youtube from '../../assets/social-media/youtube.svg'
import coinmarketcap from '../../assets/social-media/coinmarketcap-1.svg'
import twitter from '../../assets/social-media/twitter.svg'
import medialog from '../../assets/social-media/m.svg'
import discord from '../../assets/social-media/discover.svg'
import linkdin from '../../assets/social-media/linkdin.svg'
import facebook from '../../assets/social-media/facebook.jpeg'
import instagram from '../../assets/social-media/Instagram.webp'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='relative bg-zinc-950 py-20 overflow-hidden isolate'>
            <div className='absolute top-0 z-0'>
                <img src={require('../../assets/bg-line.png')} className='object-contain w-full h-full opacity-30' alt='' />
            </div>
            <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#7113fa] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>
            <Container maxWidth="lg" className='relative'>
                <Grid container spacing={2}>
                    <Grid item lg="6" md="6" sm="12" xs="12">
                        <div className='lg:pr-4'>
                            <div className='mb-4'>
                                <img src={require('../../assets/logo/palm-smart-chain-logo.png')} className='w-36' />
                            </div>
                            <div>
                                <p className='text-gray-400'>PALM will be the future of financing and the go-to solution for startups, revolutionizing how businesses in Bahrain access capital and scale their operations.</p>
                            </div>
                            <div className='mt-10'>
                                <div class="flex mt-4 lg:justify-start md:justify-center sm:justify-center justify-center sm:mt-0 lg:gap-2 md:gap-2 sm:gap-2 gap-2">
                                    <Link to="https://www.facebook.com/palmsmartchain" target="_blank" class="text-green-500 hover:text-green-500 transition-all hover:-mt-2 bg-neutral-800 hover:bg-slate-100 lg:w-11 md:w-11 sm:w-11 w-11 lg:h-11 md:h-11 sm:h-11 h-11 flex items-center justify-center rounded-full">
                                        <img src={facebook} className="w-6 h-6 rounded-full" />
                                    </Link>
                                    <Link to="https://www.instagram.com/palmsmartchain/" target="_blank" class="text-green-500 hover:text-green-500 transition-all hover:-mt-2 bg-neutral-800 hover:bg-slate-100 lg:w-11 md:w-11 sm:w-11 w-11 lg:h-11 md:h-11 sm:h-11 h-11 flex items-center justify-center rounded-full">
                                        <img src={instagram} className="w-6 h-6" />
                                    </Link>
                                    <Link to="https://x.com/palmsmartchain" target="_blank" class="text-green-500 hover:text-green-500 transition-all hover:-mt-2 bg-neutral-800 hover:bg-slate-100 lg:w-11 md:w-11 sm:w-11 w-11 lg:h-11 md:h-11 sm:h-11 h-11 flex items-center justify-center rounded-full">
                                        <img src={twitter} className="w-6 h-6" />
                                    </Link>
                                    <Link to="https://www.linkedin.com/company/palmsmartchain" target="_blank" class="text-green-500 hover:text-green-500 transition-all hover:-mt-2 bg-neutral-800 hover:bg-slate-100 lg:w-11 md:w-11 sm:w-11 w-11 lg:h-11 md:h-11 sm:h-11 h-11 flex items-center justify-center rounded-full">
                                        <img src={linkdin} className="w-6 h-6" />
                                    </Link>
                                    <Link to="https://medium.com/@palmsmartchain" target="_blank" class="text-green-500 hover:text-green-500 transition-all hover:-mt-2 bg-neutral-800 hover:bg-slate-100 lg:w-11 md:w-11 sm:w-11 w-11 lg:h-11 md:h-11 sm:h-11 h-11 flex items-center justify-center rounded-full">
                                        <img src={medialog} className="w-6 h-6" />
                                    </Link>
                                    <Link to="https://discord.com/channels/1247849623603773533/1248141333667909643" target="_blank" class="text-green-500 hover:text-green-500 transition-all hover:-mt-2 bg-neutral-800 hover:bg-slate-100 lg:w-11 md:w-11 sm:w-11 w-11 lg:h-11 md:h-11 sm:h-11 h-11 flex items-center justify-center rounded-full">
                                        <img src={discord} className="w-6 h-6" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="5" md="5" sm="12" xs="12" className='lg:border-l lg:border-gray-700' margin="auto">
                        <div className='lg:mx-7'>
                            <div className='text-center py-5'>
                                    <img src={require('../../assets/Flag_of_Bahrain.png')} className='w-28 rounded-lg object-contain m-auto' />
                                </div>
                                <div className='mb-4'>
                                    <h4 className='text-3xl text-center gradient-text'>Head Office</h4>
                                </div>
                            <div>
                            <p className='text-gray-400  text-center mb-3'><span className='text-xl font-medium'></span>Nordic tower, Floor 21, Office 212, Seef district kingdom of Bahrain.</p>
                                
                            </div>
                            
                        </div>
                    </Grid>
    
                </Grid>
                <div className='text-center'>
                    <p className='text-white'>Copyright © 2024 PALM. All rights reserved.</p>
                </div>
            </Container>
            <div aria-hidden="true" className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#8046fc] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                />
            </div>
        </div>
    )
}

export default Footer