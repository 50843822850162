import React from "react";
import heroImages from "../../../assets/home.png";
import { HashLink as Link } from "react-router-hash-link";
import { TypeAnimation } from "react-type-animation";
import SecurelyConnected from "./SecurelyConnected";
import OurServices from "./OurServices";
import EVMBlockchainSupportedWallets from "./EVMBlockchainSupportedWallets";
import PSCProducts from "./PSCProducts";
import Team from "./Team";
import Roadmap from "./Roadmap";
import PalmTokonomics from "./PalmTokonomics";
import JoinUs from "./JoinUs";
import Referral from "./Referral";
import FAQ from "./FAQ";
import PalmsAnalysis from "./PalmsAnalysis";
import OurOfficeBranch from "./OurOfficeBranch";

const Home = () => {
    return (
        <div>
            <div id="home" className="hero-section bg-cover bg-no-repeat bg-top lg:pt-20 pt-10 relative md:before:blur-190 md:before:max-w-350 md:before:h-350 md:before:w-full md:before:absolute md:before:inline-block md:before:top-0 md:after:blur-190 md:after:max-w-350 md:after:h-350 md:after:w-full md:after:absolute md:after:inline-block md:after:top-0 md:after:right-0">
                <div className="bg-home bg-home2 relative">
                    <div className="mx-auto max-w-7xl">
                        <div className="relative z-10">
                            <div className="text-center text-white text-4xl font-bold">PALM Smart Chain in Action</div>
                            <div className="relative -z-0 text-center">
                                <TypeAnimation
                                    sequence={[
                                        "EVM Blockchain Development",
                                        "Token Creation",
                                        "Staking Platform",
                                        "Crypto Wallets",
                                        "NFT Marketplace/ NFT Minting",
                                        "Bridge/ICO/Presale",
                                        "Dex / IDO",
                                        "Crypto Wallet Payment",
                                        "Real Assets Tokenization",
                                        "Auditor",
                                        "Web3 Games"
                                    ]}
                                    wrapper="span"
                                    speed={0.5}
                                    className="text-center gradient-text font-bold text-4xl md:text-6xl lg:text-7xl capitalize !leading-snug relative z-50"
                                    repeat={Infinity}
                                />
                            </div>
                            <div className="text-center my-9">
                                <Link
                                    to="https://explorer.palmsmartchain.io/"
                                    className="StartBuilding"
                                    target="_"
                                >
                                    Explore now
                                </Link>
                            </div>
                        </div>
                        <div className="img after:absolute after:bg-blackShade after:md:bottom-0 after:-bottom-2 after:w-full after:h-56 after:left-0">
                            <img src={heroImages} className="w-full mx-auto" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="About">
                <SecurelyConnected />
            </div>
            <div id="Ecosystem">
                <OurServices />
            </div>
            <div>
                <EVMBlockchainSupportedWallets />
            </div>
            <div id="Product">
                <PSCProducts />
            </div>
            <div id="Team">
                <Team />
            </div>
            <div id="Roadmaps">
                <Roadmap />
            </div>
            <div id="Tokenimics">
                <PalmTokonomics />
            </div>
            <div>
                <PalmsAnalysis />
            </div>
            <div>
                <FAQ />
            </div>
            <div>
                <Referral />
            </div>
            <div id="Community">
                <JoinUs />
            </div>
            <OurOfficeBranch />
        </div>
    );
};

export default Home;
