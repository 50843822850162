import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import linkdin from '../../../assets/social-media/linkdin.svg'
import twitter from '../../../assets/social-media/twitter.svg'
import pramodkumar from '../../../assets/pramod-kumar.png'
import sonu from '../../../assets/sonu-singh.jpg'
import avata from '../../../assets/male-avata.webp'
import batool from '../../../assets/batool.png';

const Team = () => {
    return (
        <div className='lg:py-36 bg-black relative isolate'>
            <div className='absolute top-0 z-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
            </div>
            <div className="text-center mb-14 relative">
                <div className="text-2xl font-normal text-white mb-3">Palm Smart Chain</div>
                <div className="text-6xl font-bold gradient-text">
                    Our Team
                </div>
                <div className='text-white text-xl font-normal py-3'>
                    Meet the dedicated professionals who drive our success with passion, innovation, and <br />
                    a commitment to excellence in every project.
                </div>
            </div>
            <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#7143fa] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>
            <Container maxWidth="lg" className='relative'>
                <Grid container spacing={2} alignItems="center">
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className="team-list">
                            <div className="team-profile border-2 border-slate-800">
                                <div className="px-5 py-12 text-center">
                                    <div className="w-24 h-24 rounded-full mx-auto overflow-hidden border-2 border-gray-700">
                                        <img src={avata} alt="profile image" className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500 rounded-full" />
                                    </div>
                                    <h5 className="mt-5 mb-2 text-xl font-medium text-white">Ghaniyah Mansoor</h5>
                                    <span className="text-sm text-white font-medium capitalize">Chief Executive Officer (CEO)</span>
                                </div>
                                <div className="flex border-t border-solid divide-x border-gray-700">
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={linkdin} className='w-6 m-auto' alt='' />
                                    </Link>
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={twitter} className='w-6 m-auto' alt='' />
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className="team-list">
                            <div className="team-profile border-2 border-slate-800">
                                <div className="px-5 py-12 text-center">
                                    <div className="w-24 h-24 rounded-full mx-auto overflow-hidden border-2 border-gray-700">
                                        <img src={pramodkumar} alt="profile image" className="w-full h-full transform hover:scale-110 transition-transform duration-500 rounded-full" />
                                    </div>
                                    <h5 className="mt-5 mb-2 text-xl font-medium text-white">Pramod Kumar</h5>
                                    <span className="text-sm text-white font-medium capitalize">Chief Technology Officer (CTO)</span>
                                </div>
                                <div className="flex border-t border-solid divide-x border-gray-700">
                                    <Link to="https://www.linkedin.com/in/choudharypramod/" target='_blank' className="text-center py-3 flex-grow">
                                        <img src={linkdin} className='w-6 m-auto' alt='' />
                                    </Link>
                                    <Link to="https://x.com/pramod_choudhar" target='_blank' className="text-center py-3 flex-grow">
                                        <img src={twitter} className='w-6 m-auto' alt='' />
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className="team-list">
                            <div className="team-profile border-2 border-slate-800">
                                <div className="px-5 py-12 text-center">
                                    <div className="w-24 h-24 rounded-full mx-auto overflow-hidden border-2 border-gray-700">
                                        <img src={avata} alt="profile image" className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500 rounded-full" />
                                    </div>
                                    <h5 className="mt-5 mb-2 text-xl font-medium text-white">Fatima Mansoor</h5>
                                    <span className="text-sm text-white font-medium capitalize">Sales Director</span>
                                </div>
                                <div className="flex border-t border-solid divide-x border-gray-700">
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={linkdin} className='w-6 m-auto' alt='' />
                                    </Link>
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={twitter} className='w-6 m-auto' alt='' />
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className="team-list">
                            <div className="team-profile border-2 border-slate-800">
                                <div className="px-5 py-12 text-center">
                                    <div className="w-24 h-24 rounded-full mx-auto overflow-hidden border-2 border-gray-700">
                                        <img src={avata} alt="profile image" className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500 rounded-full" />
                                    </div>
                                    <h5 className="mt-5 mb-2 text-xl font-medium text-white">Dani Gomes </h5>
                                    <span className="text-sm text-white font-medium capitalize">Chief Financial Officer (CFO)</span>
                                </div>
                                <div className="flex border-t border-solid divide-x border-gray-700">
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={linkdin} className='w-6 m-auto' alt='' />
                                    </Link>
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={twitter} className='w-6 m-auto' alt='' />
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className="team-list">
                            <div className="team-profile border-2 border-slate-800">
                                <div className="px-5 py-12 text-center">
                                    <div className="w-24 h-24 rounded-full mx-auto overflow-hidden border-2 border-gray-700">
                                        <img src={batool} alt="profile image" className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500 rounded-full" />
                                    </div>
                                    <h5 className="mt-5 mb-2 text-xl font-medium text-white">Batool Mansoor</h5>
                                    <span className="text-sm text-white font-medium capitalize">Marketing Director</span>
                                </div>
                                <div className="flex border-t border-solid divide-x border-gray-700">
                                    <Link to="https://sa.linkedin.com/in/batool-louloui-85ba3a139" className="text-center py-3 flex-grow">
                                        <img src={linkdin} className='w-6 m-auto' alt='' />
                                    </Link>
                                    <Link to="/" className="text-center py-3 flex-grow">
                                        <img src={twitter} className='w-6 m-auto' alt='' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className="team-list">
                            <div className="team-profile border-2 border-slate-800">
                                <div className="px-5 py-12 text-center">
                                    <div className="w-24 h-24 rounded-full mx-auto overflow-hidden border-2 border-gray-700">
                                        <img src={sonu} alt="profile image" className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500 rounded-full" />
                                    </div>
                                    <h5 className="mt-5 mb-2 text-xl font-medium text-white">Sonu Singh</h5>
                                    <span className="text-sm text-white font-medium capitalize">Project Manger</span>
                                </div>
                                <div className="flex border-t border-solid divide-x border-gray-700">
                                    <Link to="https://www.linkedin.com/in/sonu-singh-3601b223a" target='_blank' className="text-center py-3 flex-grow">
                                        <img src={linkdin} className='w-6 m-auto' alt='' />
                                    </Link>
                                    <Link to="https://x.com/sonusingh0284?t=Vb7UHG7TJHaCzy3eHd4Zag&s=09" target='_blank' className="text-center py-3 flex-grow">
                                        <img src={twitter} className='w-6 m-auto' alt='' />
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Team